import React from 'react'
import styled from '@emotion/styled'
import TextLink from 'src/components/TextLink'
import Link from 'src/components/Link'
import ClippedImage from 'src/components/ClippedImage'
import { typography, animations, colors } from 'src/styles'

const Wrapper = styled.div`
	padding-bottom: 10px;
	&:hover {
		color: ${ colors.pink5 };
	}
`

const ImageWrapper = styled(Link)`
	display: block;
	.gatsby-image-wrapper {
		transition: transform ${ animations.mediumSpeed } ease-in-out;
	}
	&:hover {
		.gatsby-image-wrapper {
			transform: scale(1.05);
		}
	}
`

const TextWrapper = styled(Link)`
	h6 {
		text-align: left;
		margin-bottom: 0;
		padding-top: 1.2em;
	}

	p {
		${ typography.body };
		line-height: 1.6em;
		text-align: left;
		margin-top: .4em;
	}
`

const Clips = ['raggedThumb3', 'raggedThumb1', 'raggedThumb3']

const CocktailCard = ({
	className,
	title,
	tagline,
	image,
	slug,
	index,
	sizes
}) => {
	let buttonStagger = 0
	if (index % 3) {
		buttonStagger = 1
	} else if (index % 2) {
		buttonStagger = 2
	}
	return (
		<Wrapper className={className}>
			{image && image.gatsbyImageData && (
				<ImageWrapper to={'/cocktails/' + slug}>
					<ClippedImage
						image={image}
						clipPathId={Clips[(index + 1) % 3]}
						sizes={sizes}
					/>
				</ImageWrapper>
			)}
			<TextWrapper to={'/cocktails/' + slug}>
				<p>{title}</p>
			</TextWrapper>
		</Wrapper>
	)
}

export default CocktailCard
