import React, { useContext } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { OverlayContext } from 'src/state/OverlayState'
import Section from 'src/components/Section'
import ScrollEntrance from 'src/components/ScrollEntrance'
import TextLockup, { StyledUnderline, underlineColors } from 'src/components/TextLockup'
import Grid from 'src/components/Grid'
import ProductSlider from 'src/components/MerchATF/components/ProductSlider'
import ClippedImage from 'src/components/ClippedImage'
import Button from 'src/components/Button'
import BeverageProductSwitcher from 'src/components/BeverageProductSwitcher'
import EyebrowUnderline from 'src/assets/images/ette-eyebrow-underline.svg'
import { themes } from 'src/styles/themes'

import { colors, mq, typography, globals, util } from 'src/styles'

const Wrapper = styled(Section)`
  ${ ({ isFirstSection }) => isFirstSection ? `
    padding-top: 0 !important;
  ` : '' }
  ${ ({ productPage }) => productPage ? `
    padding-bottom: 0 !important;
  ` : '' }
`

const ImageWrap = styled.div`
  position: relative;
  ${ mq.largerAndUp } {
    ${ ({ isFirstSection }) => isFirstSection ? `
      min-height: 75vh;
    ` : '' }
  }
`

const InfoWrap = styled(Grid)`
  height: 100%;
  align-items: center;
  ${ mq.largerAndUp } {
    ${ ({ isFirstSection }) =>
      isFirstSection ? `
      ${ globals.verticalSpacing('padding-top', 0.25) }
    ` : '' }
  }
  h4 {
    width: 100%;
    text-align: center;
  }
  p {
    max-width: 36em;
    margin-top: 0;
    margin-bottom: 0;
  }
  svg {
    display: block;
    &.eyebrow-underline {
      margin: 20px auto;
      * {
        fill: ${ ({ theme }) => theme ? underlineColors[theme] : colors.mainColor };
      }
    }
  }
`

const InfoCard = styled(ScrollEntrance)`
  ${ globals.verticalSpacing('padding-top') }
  ${ globals.verticalSpacing('padding-bottom', 1.2) }
  ${ globals.verticalSpacing('padding-left', 0.5) }
  ${ globals.verticalSpacing('padding-right', 0.5) }
  ${ mq.largerAndUp } {
    ${ globals.verticalSpacing('padding-top') }
    ${ globals.verticalSpacing('padding-bottom') }
  }
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const ProductInfo = styled.div`
  ${ util.responsiveStyles('margin-top', 40, 30, 24, 22) }
  ${ util.responsiveStyles('margin-bottom', 40, 30, 24, 22) }
  text-align: center;
  h6 {
    ${ util.responsiveStyles('margin-bottom', 40, 30, 24, 22) }
  }
`

const Tagline = styled.div`
  > div {
    margin: 0 auto;
    ${ typography.productTagline }
    max-width: 10em;
    u {
      display: inline-block;
      position: relative;
      text-decoration: none;
      svg {
        position: absolute;
        width: 100%;
        display: block;
        top: calc(100% - 0.1em);
        overflow: visible;
        height: 0.2em;
        * {
          fill: none;
          // stroke-width: 1.5px;
          stroke: var(--main-color);
        }
      }
    }
  }
`

const TaglineStar = styled.span`
  --accent: ${ ({ accentColor }) => accentColor || colors.pink4 };
  ${ util.responsiveStyles('font-size', 54, 36, 32, 24) }
  font-family: ${ typography.secondaryFont };
  text-align: center;
  color: var(--accent);
  display: block;
  margin-bottom: 0.25em;
`

const ProductDetailWrap = styled.div`
  --accent: ${ ({ accentColor }) => accentColor || colors.pink4 };
  ${ util.responsiveStyles('margin-top', 40, 30, 24, 22) }
  display: flex;
  justify-content: center;
  h6 {
    margin-top: 0;
    display: inline-block;
  }
  span {
    color: var(--accent);
  }
`

const SectionClippedImage = styled(ClippedImage)`
  height: 100%;
  .gatsby-image-wrapper {
    height: 100%;
    > div {
      height: 100%;
      ${ mq.mediumAndBelow } {
        height: auto;
        padding-top: 100% !important;
      }
    }
  }
  ${ mq.largeAndBelow } {
    ${ ({ isFirstSection }) => isFirstSection ? `
      clip-path: url(#raggedEdgeBottom);
    ` : `
      clip-path: url(#raggedEdgeTopBottom);
    ` }
  }
`

const ImageOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 20%;
  background: linear-gradient(180deg, rgba(0,0,0,.15) 0%, rgba(0,0,0,0) 100%);
  z-index: 2;
`

const BeverageProductATF = ({
  className,
  theme = 'default',
  cardTheme,
  prevTheme,
  nextTheme,
  isFirstSection,
  product,
  variant,
  cmsProduct,
  productPage,
  index,
  beverages,
  purchaseLink,
  pathname
}) => {
  const {
    toggleModal,
    setCurrentVariant,
    setCurrentProduct
  } = useContext(OverlayContext)

  const getMetafield = key => {
    const metafields = product?.metafields?.filter(field => field.key === key)
    if (metafields && metafields.length > 0) {
      return metafields[0].value
    }
    return false
  }
  const abv = getMetafield('abv')
  const volume = getMetafield('volume')
  let tagline = getMetafield('tagline')

  if (tagline) {
    tagline = tagline.replace(/<u>/g, "<u><svg width='115px' height='5px' viewBox='0 0 115 5' preserveAspectRatio='none'><path d='M0.0810546875,4.28027344 C25.2267225,-2.34828249 39.183906,1.56675172 67.3291088,2.38867188 C82.9115465,2.84372347 98.500327,2.02213543 114.085938,1.83886719' id='Path-2-Copy' stroke='var(--main-color)'></path></svg>")
  }

  const handleAddToCart = () => {
    toggleModal('modalLocationSelector')
    setCurrentVariant(variant.shopifyId)
    setCurrentProduct(product)
  }

  return (
    <Wrapper
      className={className}
      setTheme={theme}
      prevTheme={prevTheme}
      nextTheme={nextTheme}
      isFirstSection={isFirstSection}
      index={index}
      productPage={productPage}
    >
      <Grid
        small="[12]"
        large='[12] 1 [10] 1'
        larger='[12] 2 [8] 2'
        vAlign="stretch"
      >
        <ImageWrap isFirstSection={isFirstSection}>
          <ImageOverlay />
          <ProductSlider
            productImages={product.images}
            fullHeight={true}
          />
        </ImageWrap>

        <div>
          <InfoWrap
            small="container"
            medium="2 [10] 2"
            large="[1]"
            isFirstSection={isFirstSection}
            theme={theme}
          >
            <InfoCard>
              <h4 css={css`${ typography.eyebrow } margin: 0; font-size: 18px;`}>
                {product.title}
              </h4>
              {/* <EyebrowUnderline
                className="eyebrow-underline"
                style={{ fill: 'blue' }}
                underlineColor={underlineColors['theme' + theme]}
              /> */}
              <StyledUnderline
                className="eyebrow-underline"
                underlineColor={underlineColors[theme]}
              />
              <TextLockup
                text={<span dangerouslySetInnerHTML={{ __html: product?.descriptionHtml }} />}
                textSize="bodySmall"
                alignment="center"
                setTheme={theme}
              />

              <BeverageProductSwitcher beverages={beverages} pathname={pathname} />

              {(abv || volume) && (
                <ProductDetailWrap accentColor={underlineColors[theme]}>
                  <h6>
                    {abv && (<>{abv} ABV</>)}
                    {abv && volume && (
                      <>&nbsp;<span>&#8226;</span>&nbsp;</>
                    )}
                    {volume && (<>{volume} ML</>)}
                  </h6>
                </ProductDetailWrap>
              )}

              <ProductInfo>
                <Button
                  setTheme={themes[theme].buttonTheme}
                  title={product.buttonText || 'Buy Now'}
                  name={product.buttonText || 'Buy Now'}
                  onClick={handleAddToCart}
                  disabled={!variant.availableForSale}
                >
                  {variant.availableForSale ? (
                    product.buttonText || 'Buy Now'
                  ) : 'Sold Out'}
                </Button>
              </ProductInfo>

              {tagline && (
                <Tagline>
                  <TaglineStar accentColor={underlineColors[theme]}>*</TaglineStar>
                  <div dangerouslySetInnerHTML={{ __html: tagline }} />
                </Tagline>
              )}
            </InfoCard>
          </InfoWrap>
        </div>
      </Grid>
    </Wrapper>
  )
}

export default BeverageProductATF
