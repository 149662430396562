import React, { useEffect, useState } from 'react'
import { graphql, navigate } from 'gatsby'
import * as PropTypes from 'prop-types'

import SEO from 'src/components/SEO'
import ComponentRenderer from 'src/components/ComponentRenderer'
import Header from 'src/components/Header'
import Footer from 'src/components/Footer'
import BeverageProductATF from 'src/components/BeverageProductATF'
import Cart from 'src/components/Cart'
import { decodeProductId } from 'src/utils/format'

const propTypes = {
	data: PropTypes.object.isRequired,
}

const BeverageProductTemplate = ({ ...props }) => {
	const { data } = props
	const site = data.allContentfulSiteSettings && data.allContentfulSiteSettings.edges[0].node

	const productId = data.shopifyProductVariant.product.shopifyId

	// Get Contentful Product Data
	const cmsProduct = data.allContentfulBeverageProduct.edges.filter(product => decodeProductId(product.node.shopifyProductId) === productId)[0]?.node

	let sections = false
	if (cmsProduct?.sections) {
		sections = cmsProduct.sections
	}

	const seo = cmsProduct.seo

	// Shopify Data
	const product = data.shopifyProductVariant.product
  const variant = data.shopifyProductVariant
  const productVariants = product.variants
	const hasAtf = true

	const variantById = id => {
    return productVariants.filter(thisVariant => thisVariant.shopifyId === id)[0]
  }

  const [currentVariant, setVariant] = useState(variant)

  useEffect(() => {
    navigate('/shop/' + product.handle + '?variant=' + currentVariant.shopifyId)
  }, [currentVariant])

  useEffect(() => {
    if (location.search.includes('?variant=')) {
      navigate('/shop/' + product.handle + location.search)
      const variantIdFromUrl = String(location.search).split('?variant=')[1]
      setVariant(variantById(variantIdFromUrl))
    } else {
      navigate('/shop/' + product.handle + '?variant=' + currentVariant.shopifyId)
    }
  }, [])

  let swatchImages = false
  if (product.images && product.images[0]) {
    // weed out color swatch images
    swatchImages = product.images.filter(image => image.altText && image.altText.includes('swatch'))
    // shareImage = product.images[0].localFile.childImageSharp.fluid.src
  }

  let currentColorSelection = false
  if (currentVariant && currentVariant?.selectedOptions?.filter(option => option.name === 'Size').length > 0) {
    currentColorSelection = currentVariant?.selectedOptions?.filter(option => option.name === 'Size')[0].value
    // productImages = productImages.filter(image => image.altText && image.altText.includes(currentColorSelection))
  }

	return (
		<>
			<SEO
				title={product.title}
				description={seo?.description?.description}
				siteSettings={site}
				keywords={seo?.keywords}
				shareImage={seo?.shareImage && 'https:' + seo.shareImage.file.url}
			/>
			<Header
				hasAtf={hasAtf}
				bannerText={site.bannerText}
				bannerColor={site.bannerColor}
				navigation={site.navigation}
				rightNavigation={site.rightNavigation}
				location={product.slug}
				title={site.title}
				placeholder={false}
				cartLink={site.cartLink}
			/>
			<Cart />

			<BeverageProductATF
				isFirstSection={true}
				product={product}
				variant={currentVariant}
				cmsProduct={cmsProduct}
				productPage={true}
				beverages={data?.allContentfulBeverageProduct.edges}
				theme={cmsProduct?.theme || 'darkPink'}
				nextTheme={sections?.length ? sections[0]?.theme : false}
				pathname={props?.path}
			/>

			{sections &&
				sections.map((section, index) => {
					const prevTheme =
						index !== 0 && sections[index - 1] && sections[index - 1].theme
					const prevFullWidth =
						index !== 0 &&
						sections[index - 1] &&
						sections[index - 1].width === 'fullWidth'
					const nextTheme =
						index !== sections.length - 1 &&
						sections[index + 1] &&
						sections[index + 1].theme
					const nextFullWidth =
						index !== sections.length - 1 &&
						sections[index + 1] &&
						sections[index + 1].width === 'fullWidth'
					const lastSection = sections.length === index + 1
					return (
						<ComponentRenderer
							prevTheme={prevFullWidth ? false : prevTheme}
							nextTheme={nextFullWidth ? false : nextTheme}
							isFirstSection={index === 0}
							isLastSection={lastSection}
							key={section.id + index}
							item={section}
							index={index}
						/>
					)
				})}
			<Footer {...site} />
		</>
	)
}

BeverageProductTemplate.propTypes = propTypes

export const pageQuery = graphql`
	query ($id: String!) {
		shopifyProductVariant(shopifyId: { eq: $id }) {
      ...Variant
    }
		allContentfulSiteSettings(
			filter: { internalName: { regex: "/^((?!PLACEHOLDER).)*$/" } }
		) {
			edges {
				node {
					...SiteSettings
				}
			}
		}
		allContentfulBeverageProduct {
			edges {
				node {
					...BeverageProduct
				}
			}
		}
		allContentfulReview(
			filter: { internalName: { regex: "/^((?!PLACEHOLDER).)*$/" } }
		) {
			edges {
				node {
					...Review
				}
			}
		}
	}
`

export default BeverageProductTemplate
