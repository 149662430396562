import React, { useRef, Fragment } from 'react'
import styled from '@emotion/styled'
import Slider from 'react-slick'

import ArrowLeft from 'src/assets/images/arrow-left.svg'
import ArrowRight from 'src/assets/images/arrow-right.svg'

import Section from 'src/components/Section'
import Grid from 'src/components/Grid'
import CocktailCard from 'src/components/CocktailCard'
import ResponsiveComponent from 'src/components/ResponsiveComponent'
import { typography, mq, globals, animations, colors } from 'src/styles'

const Wrapper = styled(Section)``
const TitleBlock = styled.h4`
	${ typography.handwrittenEyebrow }
	width: 100%;
	text-align: center;
	font-family: ${ typography.secondaryFont };
	${ globals.verticalSpacing('padding-bottom', 0.5) }
`

const CocktailsWrap = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	flex-wrap: wrap;
	justify-content: center;
	margin: 0 -6px -60px;
	${ mq.mediumAndUp } {
		margin: 0 -10px -60px;
	}
	${ mq.largeAndUp } {
		margin: 0 -10px;
	}
	${ mq.largerAndUp } {
		margin: 0 -12px;
	}
	> div {
		width: ${ ({ small }) => 100 / small }%;
		flex-grow: 0;
		flex-shrink: 0;
		padding: 0 6px;
		margin-bottom: 60px;
		${ mq.mediumAndUp } {
			padding: 0 10px;
		}
		${ mq.largeAndUp } {
			padding: 0 10px;
			width: ${ ({ medium }) => 100 / medium }%;
		}
		${ mq.largerAndUp } {
			padding: 0 12px;
			width: ${ ({ large }) => 100 / large }%;
		}
	}
`

const StyledSlider = styled(Slider)``

const Slide = styled.div`
	padding: 0 20px 0 0;
	${ mq.largeAndBelow } {
		padding: 0 10px;
	}
`

const ArrowControl = styled.button`
	appearance: none;
	width: 50px;
	height: 50px;
	padding: 0;
	cursor: pointer;
	${ ({ position }) =>
		position === 'left' ? `
    margin-right: 10px;
  ` : `
    margin-left: 10px;
  ` }
	background: transparent;
	color: ${ colors.mainColor };
	border: none;
	outline: none;
	transition: color ${ animations.mediumSpeed } ease-in-out,
		opacity ${ animations.mediumSpeed } ease-in-out;
	${ ({ hide }) =>
		hide &&
		`
    // opacity: 0;
    // pointer-events: none;
  ` }
	&:hover {
		color: ${ colors.pink2 };
	}
	svg {
		width: 50px;
		height: 50px;
		display: block;

		* {
			// stroke: currentcolor;
			// stroke-width: 1px;
			// stroke-linecap: butt;
			// stroke-linebutt: butt;
			// stroke-linejoin: miter;
			transition: fill ${ animations.mediumSpeed } ease-in-out;
			fill: ${ colors.yellow };
		}
		&:hover {
			* {
				fill: ${ colors.pink5 };
			}
		}
	}
`

const ArrowWrap = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`

const MoreCocktails = ({ className, cocktails }) => {
	const customSlider = useRef()

	const NextArrow = ({ onClick }) => (
		<ArrowControl
			position="right"
			onClick={onClick}
			aria-label="Go to next image"
		>
			<ArrowRight />
		</ArrowControl>
	)
	const PrevArrow = ({ onClick }) => (
		<ArrowControl
			position="left"
			onClick={onClick}
			aria-label="Go to previous image"
		>
			<ArrowLeft />
		</ArrowControl>
	)

	function goToNextSlide () {
		customSlider.current.slickNext()
	}

	function goToPrevSlide () {
		customSlider.current.slickPrev()
	}

	const settings = {
		dots: false,
		arrows: false,
		infinite: true,
		autoplay: false,
		centerMode: true,
		swipe: true,
		accesibility: true,
		speed: 500, // swipe animation speed
		variableWidth: false,
		slidesToShow: 1,
		slidesToScroll: 1,
	}

	return (
		<Wrapper className={className} setTheme="midPink" prevTheme='default'>
			<TitleBlock>More Cocktails</TitleBlock>
			<ResponsiveComponent
				small={
					<Fragment>
						<StyledSlider
							{...settings}
							ref={slider => (customSlider.current = slider)}
						>
							{cocktails.map((cocktail, index) => {
								return (
									<Slide
										key={cocktail.node.id + '_slide'}
										slideCount={cocktails.length}
									>
										<CocktailCard
											title={cocktail.node.title}
											tagline={cocktail.node.tagline}
											image={cocktail.node.thumbnail}
											slug={cocktail.node.slug}
											index={index}
										/>
									</Slide>
								)
							})}
						</StyledSlider>
						<ArrowWrap>
							<PrevArrow onClick={goToPrevSlide} />
							<NextArrow onClick={goToNextSlide} />
						</ArrowWrap>
					</Fragment>
				}
				medium={
					<Grid small="container" larger='2 [10] 2' extraLarge='5 [18] 5'>
						<CocktailsWrap small="2" medium="2" large="3" vAlign="top">
							{cocktails.map((cocktail, index) => {
								return (
									<CocktailCard
										key={index}
										title={cocktail.node.title}
										tagline={cocktail.node.tagline}
										image={cocktail.node.thumbnail}
										slug={cocktail.node.slug}
										index={index}
									/>
								)
							})}
						</CocktailsWrap>
					</Grid>
				}
			/>
		</Wrapper>
	)
}

export default MoreCocktails
