import React from 'react'
import styled from '@emotion/styled'
import { GatsbyImage } from 'gatsby-plugin-image'

const Wrapper = styled.div`
	${ ({ clipPathId }) => clipPathId ? `
		clip-path: url(#${ clipPathId });
	` : '' }
	${ ({ aspectRatio }) => aspectRatio === 1 ? `
		padding-top: 100%;
	` : '' }
	position: relative;
`

const StyledGatsbyImage = styled(GatsbyImage)`
	vertical-align: top;
	${ ({ aspectRatio }) => aspectRatio === 1 ? `
		position: absolute;
		top: -2px;
		left: -2px;
		width: calc(100% + 4px);
		height: calc(100% + 4px);
	` : '' }

`

const ClippedImage = ({
	className,
	clipPathId,
	image,
	aspectRatio = false,
	sizes
}) => {
	if (!image && !image?.gatsbyImageData) {
		return false
	}
	return (
		<Wrapper className={className} clipPathId={clipPathId} aspectRatio={aspectRatio}>
			<StyledGatsbyImage
				image={image?.gatsbyImageData}
				loading='eager'
				alt={image?.file?.fileName}
				sizes={sizes || '100vw'}
				aspectRatio={aspectRatio}
				placeholder='none'
			/>
		</Wrapper>
	)
}

export default ClippedImage
