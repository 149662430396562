import * as util from './util'

export const verticalMargins = `
	${ util.responsiveStyles('margin-top', 96, 96, 72, 48) }
	${ util.responsiveStyles('margin-bottom', 96, 96, 72, 48) }
`

export const verticalSpacing = (attr = 'padding-top', multiple = 1) => `
	${ util.responsiveStyles(
		attr,
		150 * multiple,
		120 * multiple,
		100 * multiple,
		60 * multiple
	) }
`

export const baseBorderRadius = 4

// Buttons, Inputs, Selects, etc.
export const uiElementSizes = {
	tiny: 42,
	small: 54,
	medium: 60,
	large: 70,
}
