import React from 'react'
import styled from '@emotion/styled'
import Section from 'src/components/Section'
import Grid from 'src/components/Grid'
import Video from 'src/components/Video'
import TextLockup from 'src/components/TextLockup'
import ScrollEntrance from 'src/components/ScrollEntrance'
import ClippedImage from 'src/components/ClippedImage'
import { mq, colors, globals } from 'src/styles'
import LargeBoxSvg from 'src/assets/images/large-box-01.svg'

const FFSection = styled(Section)`
`

const InnerGrid = styled(Grid)`
  ${ ({ type }) => type === 'box' ? `
    position: relative;
    ${ globals.verticalSpacing('padding-top', 0.75) }
    ${ globals.verticalSpacing('padding-bottom', 0.75) }
    ${ mq.mediumAndBelow } {
      ${ globals.verticalSpacing('padding-top', 1.1) }
      ${ globals.verticalSpacing('padding-bottom', 1.1) }
    }
  ` : '' }
`

const ColumnWrapper = styled.div`
  position: relative;
  z-index: 2;
  h1,
  h2,
  h3 {
    max-width: 20em;
  }
  h4,
  h4 {
    max-width: 26em;
  }
  h6 {
    max-width: 16em;
  }
  p {
    max-width: 40em;
  }
`

const BoxBg = styled(LargeBoxSvg)`
  position: absolute;
  left: ${ -100 / 14 }vw;
  right: ${ -100 / 14 }vw;
  width: calc(100% + ${ 100 / 7 }vw);
  z-index: 1;
  top: 0;
  height: 100%;
  grid-column: none !important;
  * {
    ${ ({ theme }) => `fill: ${ theme?.background || colors.pink2 };` }
  }
`

const ColumnText = styled(TextLockup)`
  ${ ({ setTheme }) => `
    color: ${ setTheme?.color || colors.textColor };
    svg.short-hr * {
      fill: ${ setTheme?.lineColor || colors.pink4 };
    }
  ` }
`

const arrangeMedia = {
  left: {
    normal: '[11] 2 [11]',
    large: '[12] 2 [10]',
    extraLarge: '[13] 2 [9]',
    winXl: {
      normal: '[10] 2 [10]',
      large: '[11] 2 [9]',
      extraLarge: '[12] 2 [8]',
    }
  },
  right: {
    normal: '[11] 2 [11]',
    large: '[12] 2 [10]',
    extraLarge: '[13] 2 [9]',
    winXl: {
      normal: '[10] 2 [10]',
      large: '[11] 2 [9]',
      extraLarge: '[12] 2 [8]',
    }
  },
  bleedLeft: {
    normal: '[13] 2 [11] 2',
    large: '[14] 2 [10] 2',
    extraLarge: '[15] 2 [9] 2',
    winXl: {
      normal: '[13] 2 [11] 2',
      large: '[14] 2 [10] 2',
      extraLarge: '[15] 2 [9] 2',
    }
  },
  bleedRight: {
    normal: '[13] 2 [11] 2',
    large: '[14] 2 [10] 2',
    extraLarge: '[15] 2 [9] 2',
    winXl: {
      normal: '[13] 2 [11] 2',
      large: '[14] 2 [10] 2',
      extraLarge: '[15] 2 [9] 2',
    }
  },
}

const mediaSizes = {
  noBleed: {
    normal: (100 / 28) * 11,
    large: (100 / 28) * 12,
    extraLarge: (100 / 28) * 13,
  },
  bleed: {
    normal: (100 / 28) * 13,
    large: (100 / 28) * 14,
    extraLarge: (100 / 28) * 15,
  },
}

const Clips = ['raggedThumb1', 'raggedThumb2', 'raggedThumb3']

const boxTheme = {
  default: {
    background: colors.white,
    color: colors.textColor,
    lineColor: colors.pink4
  },
  pink: {
    background: colors.pink4,
    color: colors.white,
    lineColor: colors.pink2
  },
  midPink: {
    background: colors.pink2,
    color: colors.textColor,
    lineColor: colors.pink4
  }
}

const FiftyFifty = ({
  className,
  theme,
  prevTheme,
  nextTheme,
  media,
  mediaPlacement,
  mediaWidth,
  mediaStyle = 'organicRectangle',
  eyebrow,
  text,
  type,
  actions,
  paragraphSize,
  verticalAlignment,
  isFirstSection,
  index,
}) => {
  if (!mediaPlacement || mediaPlacement === null) {
    mediaPlacement = 'left'
  }
  if (media.fluid) {
    let imageSize = mediaSizes.noBleed[mediaWidth]
    if (mediaPlacement.includes('bleed')) {
      imageSize = mediaSizes.bleed[mediaWidth]
    }
    media.fluid.sizes =
      '(min-width: ' + mq.mediumBreakpoint + 'px) ' + imageSize + 'vw, 86vw'
  }
  const getClip = mediaStyle => {
    if (mediaStyle === 'none') {
      return ''
    } else if (mediaStyle === 'flower') {
      return 'cocktailHero'
    } else {
      return Clips[Math.floor(Math.random() * Math.floor(3))]
    }
  }
  return (
    <FFSection
      className={className}
      setTheme={type === 'box' ? 'default' : theme}
      prevTheme={prevTheme}
      nextTheme={nextTheme}
      isFirstSection={isFirstSection}
      index={index}
      type={type}
    >
      <Grid
        small={type === 'box' ? '3 [22] 3' : '1 [12] 1'}
        medium={type === 'box' ? '3 [22] 3' : '1 [12] 1'}
      >
        <InnerGrid
          small="[1]"
          large={arrangeMedia[mediaPlacement || 'left'][mediaWidth || 'normal']}
          extraLarge={arrangeMedia[mediaPlacement || 'left'].winXl[mediaWidth || 'normal']}
          rowGap={['6vw', '6vw', '80px']}
          vAlign={verticalAlignment || 'center'}
          type={type}
          gridDirection={
            mediaPlacement.includes('right') || mediaPlacement.includes('Right') ? 'rtl' : 'ltr'
          }
        >
          <ColumnWrapper>
            {media.file.contentType === 'video/mp4' ? (
              <ScrollEntrance>
                <Video video={media} />
              </ScrollEntrance>
            ) : (
              <ScrollEntrance>
                <ClippedImage
                  image={media}
                  clipPathId={getClip(mediaStyle)}
                  aspectRatio={mediaStyle === 'flower' && 1}
                />
              </ScrollEntrance>
            )}
          </ColumnWrapper>

          <ColumnWrapper>
            <ColumnText
              entranceDelay={1}
              alignment="left"
              eyebrow={eyebrow}
              eyebrowUnderline={false}
              text={text}
              textSize={'body'}
              handwrittenEyebrow={true}
              actions={actions}
              theme={theme}
              setTheme={boxTheme[theme]}
            />
          </ColumnWrapper>
          {type === 'box' && (
            <BoxBg theme={boxTheme[theme]} />
          )}
        </InnerGrid>
      </Grid>
    </FFSection>
  )
}

FiftyFifty.defaultProps = {
  mediaWidth: 'normal',
  mediaPlacement: 'left',
}

export default FiftyFifty
