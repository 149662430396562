import React, { useRef, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { useStaticQuery, graphql } from 'gatsby'
import { css } from '@emotion/react'
import Section from 'src/components/Section'
import Link from 'src/components/Link'
import TextLink from 'src/components/TextLink'
import ScrollEntrance from 'src/components/ScrollEntrance'
import TextLockup from 'src/components/TextLockup'
import Grid from 'src/components/Grid'
import { themes } from 'src/styles/themes'
import { decodeProductId } from 'src/utils/format'
import { GatsbyImage } from 'gatsby-plugin-image'
import Slider from 'react-slick'
import ArrowLeft from 'src/assets/images/arrow-left.svg'
import ArrowRight from 'src/assets/images/arrow-right.svg'

import { animations, colors, mq, typography, globals, util } from 'src/styles'

const Wrapper = styled(Section)`
  ${ ({ isFirstSection }) => isFirstSection ? `
    padding-top: 0 !important;
  ` : '' }
  ${ ({ productPage }) => productPage ? `
    padding-bottom: 0 !important;
  ` : '' }
`

const CardClip = styled.div`
  ${ ({ clipId = 'raggedThumb1' }) => `
    clip-path: url(#${ clipId });
  ` }
`

const ProductCard = styled(Link)`
  display: block;
  margin: 0 -10px 0 0;
  &:hover {
    img {
      transform: scale(1.05);
    }
  }
`

const ArrowControl = styled.button`
	appearance: none;
	width: 40px;
	height: 40px;
	padding: 0;
	cursor: pointer;
	background: transparent;
  background: ${ colors.pink2 };
  border-radius: 50%;
  padding: 8px;
	color: currentcolor;
	border: none;
	outline: none;
	transition:
    color ${ animations.mediumSpeed } ease-in-out,
    background ${ animations.mediumSpeed } ease-in-out,
    opacity ${ animations.mediumSpeed } ease-in-out;
	&:hover {
    background: ${ colors.pink3 };
	}
	svg {
		width: 100%;
		aspect-ratio: 1;
		display: block;
		* {
			transition: fill ${ animations.mediumSpeed } ease-in-out;
			fill: currentcolor;
      stroke-width: 2px;
      stroke: currentColor;
		}
		&:hover {
			* {
				fill: ${ colors.pink5 };
			}
		}
	}
`

const StyledSlider = styled(Slider)`
	overflow: visible !important;
  max-width: calc((100vw / 14) * 12 + 80px);
  margin: 0 auto;
  ${ mq.mediumAndBelow } {
    max-width: unset;
    margin: 0 -15vw;
  }
  ${ mq.smallAndBelow } {
    margin: 0 -30vw;
  }
	.slick-list,
	.slick-track {
		overflow: visible !important;
	}
	.slick-track {
		${ mq.largerAndUp } {
			display: flex;
			justify-content: flex-end;
		}
	}
	&:hover {
		${ ArrowControl } {
			transform: none;
		}
	}
`

const PressSlide = styled.div`
	padding: 0 10px 0;
  a {
    display: flex;
    justify-content: center;
  }
	> div {
    display: flex;
    justify-content: center;
		width: auto;
	}
	${ mq.largeAndBelow } {
		padding: 0 10px;
	}
`

const InfoWrap = styled.div`
	position: relative;
	padding-bottom: 50px;
	p {
		max-width: 50em;
		text-wrap: balance;
	}
	${ mq.mediumAndBelow } {
		.floater-button {
			display: none;
		}
	}
	${ mq.mediumAndUp } {
		display: flex;
		justify-content: space-between;
	}
	${ mq.largeAndUp } {
		.lockup-actions {
			display: none;
		}
	}
`

const PressInfoWrap = styled.div`
	position: relative;
	padding-bottom: 40px;
  ${ globals.verticalSpacing('padding-top') }
	p {
		max-width: 50em;
		text-wrap: balance;
	}
`

const CardImg = styled(GatsbyImage)`
  aspect-ratio: 1;
	img {
    transition: all ${ animations.mediumSpeed } ease-in-out !important;
    object-fit: cover !important;
  }
`

const LogoImage = styled(GatsbyImage)`
  aspect-ratio: 1.5;
	img {
    object-fit: cover !important;
  }
`

const SliderControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding-top: 20px;
  pointer-events: none;
  ${ ({ slideCount }) => `
    ${ slideCount <= 9 ? `
      display: none;
    ` : `
      display: flex;
    ` }
    ${ mq.maxWidth(1400) } {
      ${ slideCount <= 7 ? `
        display: none;
      ` : `
        display: flex;
      ` }
    }
    ${ mq.maxWidth(1200) } {
      ${ slideCount <= 5 ? `
        display: none;
      ` : `
        display: flex;
      ` }
    }
    ${ mq.maxWidth(600) } {
      ${ slideCount <= 3 ? `
        display: none;
      ` : `
        display: flex;
      ` }
    }
  ` }
  button {
    pointer-events: all;
  }
`

const CardInfo = styled.div`
  padding: 7% 8% 12%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
  text-align: center;
  text-wrap: balance;
  row-gap: 20px;
  column-gap: 20px;
  h3 {
    margin: 0 0 5px;
  }
  ${ ({ card, theme }) => `
    background-color: ${ themes[theme].background };
    color: ${ themes[theme].color };
    --bg-color: ${ themes[theme].background };
    --text-color: ${ themes[theme].color };
    --main-color: ${ themes[theme].hoverColor };
` }
  ${ mq.largerAndUp } {
    padding: 3% 6% 5%;
    flex-direction: row;
    text-align: left;
    h3 {
      margin: -.3em;
    }
  }
  > div:last-of-type {
    flex-shrink: 0;
  }
  .tagline {
    ${ typography.bodySmall }
    line-height: 1.4em;
    max-width: 20em;
    ${ mq.largerAndUp } {
      max-width: 100%;
    }
    u {
      display: inline-block;
      position: relative;
      text-decoration: none;
      svg {
        position: absolute;
        width: 100%;
        display: block;
        top: calc(100% + 0em);
        opacity: .5;
        overflow: visible;
        height: 0.2em;
        * {
          fill: none;
          stroke-width: 2px;
          stroke: var(--main-color);
        }
      }
    }
  }
`

const FeaturedProduct = ({
  className,
  theme,
  cardTheme,
  prevTheme,
  nextTheme,
  isFirstSection,
  beverageProduct,
  productPage,
  imageOnLeft = true,
  headline,
  text,
  buttons,
  product,
  index,
  purchaseLink,
  pressHeadline,
  pressText,
  pressItems
}) => {
  const customSlider = useRef()

	const NextArrow = ({ onClick }) => {
    return (
      <ArrowControl
        position="right"
        onClick={() => {
          onClick()
        }}
        aria-label='Go to next image'
      >
        <ArrowRight/>
      </ArrowControl>
    )
  }
	const PrevArrow = ({ onClick }) => {
    return (
      <ArrowControl
        position="left"
        onClick={() => {
          onClick()
        }}
        aria-label='Go to previous image'
      >
        <ArrowLeft/>
      </ArrowControl>
    )
  }

	function goToNextSlide () {
		customSlider.current.slickNext()
	}

	function goToPrevSlide () {
		customSlider.current.slickPrev()
	}

  const settings = {
		dots: false,
		arrows: false,
		infinite: true,
		autoplay: true,
    autoplaySpeed: 2000,
		centerMode: true,
		swipe: true,
		accesibility: false,
		speed: 500, // swipe animation speed
		variableWidth: false,
		slidesToShow: 9,
		slidesToScroll: 1,
		// nextArrow: <NextArrow/>,
		// prevArrow: <PrevArrow onClick={goToPrevSlide}/>,
		responsive: [
      {
				breakpoint: 1400,
				settings: {
					slidesToShow: 7,
				}
			},
      {
				breakpoint: 1200,
				settings: {
					slidesToShow: 5,
				}
			},
			{
				breakpoint: 769,
				settings: {
					slidesToShow: 5,
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 3,
				}
			},
			{
				breakpoint: 500,
				settings: {
					slidesToShow: 3,
				}
			}
		]
	}

  const { allShopifyProductVariant, allShopifyProduct, allContentfulBeverageProduct } = useStaticQuery(
    graphql`
      query {
        allShopifyProduct {
			    edges {
			      node {
			        handle
			        shopifyId
			      }
			    }
			  }
        allContentfulBeverageProduct {
          edges {
            node {
              ...BeverageProduct
            }
          }
        }
        allShopifyProductVariant {
          edges {
            node {
              ...Variant
            }
          }
        }
      }
    `
  )

  const getProductLink = id => {
    const decodedId = decodeProductId(id)
    const product = allShopifyProduct?.edges?.filter(product => product.node.shopifyId === decodedId)[0]?.node
    return '/shop/' + product?.handle
  }

  const getMetafield = (key, product) => {
    const metafields = product?.metafields?.filter(field => field.key === key)
    if (metafields && metafields.length > 0) {
      return metafields[0].value
    }
    return false
  }

  return (
    <Wrapper
      className={className}
      setTheme='default'
      prevTheme={prevTheme}
      nextTheme={nextTheme}
      isFirstSection={isFirstSection}
      index={index}
      productPage={productPage}
    >
      <Grid small='1 [12] 1'>
        <InfoWrap>
          <TextLockup
            eyebrowUnderline={false}
            eyebrow={headline}
            text={text}
            textSize={'body'}
            handwrittenEyebrow={true}
            actions={buttons}
            alignment={'left'}
          />
          <div className='floater-button'>
            {buttons.map((action, index) => {
              let to = '/'
              if (action?.linkToPage?.shopifyProductId) {
                to = getProductLink(action?.linkToPage?.shopifyProductId)
              } else if (action?.linkToPage?.slug) {
                to = '/' + action.linkToPage.slug
              } else if (action?.to) {
                to = action.to
              }
              return (
                <div key={'cocktail-button-' + index}>
                  <TextLink
                    to={to}
                    external={action.to}
                    target={action.openInNewTab ? '_blank' : ''}
                    title={action.label}
                    name={action.label}
                  >
                    {action.label}
                  </TextLink>
                </div>
              )
            })}
          </div>
        </InfoWrap>
      </Grid>
      <Grid
        as={Grid}
        small="1 [12] 1"
        vAlign="stretch"
      >
        <Grid colGap='2vw' rowGap='7vw' small="[1]" large="[1] [1]">
          {product?.map((prod, index) => {
            if (!prod?.shopifyProductId) {
              return false
            }

            const beverageProductId = decodeProductId(prod.shopifyProductId)
            const variant = allShopifyProductVariant.edges.filter(variant => variant.node.product.shopifyId === beverageProductId)[0]?.node
            const product = variant?.product

            let tagline = getMetafield('tagline', product)

            if (tagline) {
              tagline = tagline.replace(/<u>/g, "<u><svg width='115px' height='5px' viewBox='0 0 115 5' preserveAspectRatio='none'><path d='M0.0810546875,4.28027344 C25.2267225,-2.34828249 39.183906,1.56675172 67.3291088,2.38867188 C82.9115465,2.84372347 98.500327,2.02213543 114.085938,1.83886719' id='Path-2-Copy' stroke='var(--main-color)'></path></svg>")
            }

            const clips = [
              'raggedThumb3',
              'raggedThumb3'
            ]

            return (
              <ScrollEntrance key={prod.shopifyProductId + '-' + index}>
                <CardClip clipId={clips[index]}>
                  <ProductCard
                    to={'/shop/' + product.handle}
                  >
                    <div>
                      <CardImg image={product?.images[0]?.image?.gatsbyImageData} />
                      <CardInfo theme={prod.theme}>
                        <div>
                          <h3 className='h5'><span className="handwritten">{product?.title}</span></h3>
                          <div className="body tagline" dangerouslySetInnerHTML={{ __html: tagline }} />
                        </div>
                        <div>
                          <TextLink active>Shop Now</TextLink>
                        </div>
                      </CardInfo>
                    </div>
                  </ProductCard>
                </CardClip>
              </ScrollEntrance>
            )
          })}
        </Grid>
      </Grid>

      <Grid small='1 [12] 1'>
        <PressInfoWrap>
          <TextLockup
            eyebrowUnderline={false}
            eyebrow={pressHeadline}
            text={pressText}
            textSize={'body'}
            handwrittenEyebrow={true}
            alignment={'center'}
          />
        </PressInfoWrap>
      </Grid>
      <div style={{ overflow: 'hidden', position: 'relative' }}>
        <StyledSlider
          {...settings}
          ref={slider => (customSlider.current = slider)}
        >
          {pressItems.map(logo => {
            if (!logo?.media?.gatsbyImageData) {
              return false
            }
            if (logo?.eyebrow?.includes('http://') || logo?.eyebrow?.includes('https://')) {
              return (
                <PressSlide key={logo.id}>
                  <Link to={logo?.eyebrow} external target="_blank">
                    <LogoImage image={logo?.media?.gatsbyImageData} />
                  </Link>
                </PressSlide>
              )
            }

            return (
              <PressSlide key={logo.id}>
                <LogoImage image={logo?.media?.gatsbyImageData} />
              </PressSlide>
            )
          })}
        </StyledSlider>
        <SliderControls slideCount={pressItems?.length}>
          <PrevArrow onClick={goToPrevSlide}/>
          <NextArrow onClick={goToNextSlide}/>
        </SliderControls>
      </div>
    </Wrapper>
  )
}

export default FeaturedProduct
