import { rgba } from 'polished'
import * as colors from './colors'
import { responsiveStyles } from './util'

import './fonts'

// Place global Typography in this file
export const primaryFont = 'Mallory, -apple-system, serif'
export const secondaryFont = 'Dads Recipe, -apple-system, serif'
export const medium = 600
export const bold = 800

export const bodyLarge = `
	font-size: 16px;
	${ responsiveStyles('font-size', 16, 15, 15, 14) }
	line-height: 2em;
	font-family: ${ primaryFont };
	letter-spacing: -0.007em;
	text-transform: none;
	font-weight: normal;
`
export const bodyMedium = `
	font-size: 16px;
	${ responsiveStyles('font-size', 16, 15, 15, 14) }
	line-height: 1.666em;
	font-family: ${ primaryFont };
	letter-spacing: 0.03em;
	text-transform: none;
	font-weight: normal;
`
export const body = `
	font-size: 16px;
	${ responsiveStyles('font-size', 16, 15, 15, 14) }
	line-height: 1.666em;
	font-family: ${ primaryFont };
	letter-spacing: 0.03em;
	text-transform: none;
	font-weight: normal;
`
export const bodySmall = `
	font-size: 16px;
	${ responsiveStyles('font-size', 15, 14, 14, 14) }
	line-height: 2em;
	font-family: ${ primaryFont };
	letter-spacing: 0.03em;
	text-transform: none;
	font-weight: normal;
`
export const bodyTiny = `
	${ responsiveStyles('font-size', 13, 13, 12, 11) }
	line-height: 1.7em;
	font-family: ${ primaryFont };
	letter-spacing: 0.036em;
	text-transform: none;
	font-weight: normal;
`

export const h1 = `
	${ responsiveStyles('font-size', 50, 40, 37, 30) }
	line-height: 1.359em;
	font-family: ${ primaryFont };
	font-weight: normal;
	letter-spacing: -0.015em;
	text-transform: uppercase;
`

export const h2 = `
	${ responsiveStyles('font-size', 46, 36, 32, 24) }
	line-height: 1.3em;
	font-family: ${ primaryFont };
	font-weight: normal;
	letter-spacing: -0.015em;
	text-transform: uppercase;
`

export const h3 = `
	${ responsiveStyles('font-size', 42, 32, 27, 22) }
	line-height: 1.5em;
	font-family: ${ primaryFont };
	font-weight: normal;
	letter-spacing: -0.03em;
	text-transform: uppercase;
`

export const h4 = `
	${ responsiveStyles('font-size', 30, 22, 18, 16) }
	line-height: 1.4em;
	font-family: ${ primaryFont };
	font-weight: normal;
	letter-spacing: 0.166em;
	text-transform: uppercase;
`

export const h5 = `
	${ responsiveStyles('font-size', 22, 19, 17, 14) }
	font-family: ${ primaryFont };
	font-weight: normal;
	line-height: 1.5em;
	letter-spacing: 0.125em;
	text-transform: uppercase;
`
export const h6 = `
	font-size: 14px;
	font-family: ${ primaryFont };
	font-weight: ${ medium };
	line-height: 1.5em;
	letter-spacing: .11125em;
	text-transform: uppercase;
`

export const blockquote = `
	${ bodyLarge }
	font-style: normal;
`

export const reviewQuote = `
	font-family: ${ secondaryFont };
	${ responsiveStyles('font-size', 42, 38, 36, 32) }
	letter-spacing: -0.0216em;
	line-height: .9em;
	text-transform: lowercase;
`

export const eyebrow = `
	${ h6 }
	font-size: 18px;
`

export const smallCaps = `
	${ h6 }
	${ responsiveStyles('font-size', 13, 12, 12, 12) }
`

export const tinyCaps = `
	${ h6 }
	${ responsiveStyles('font-size', 12, 12, 12, 12) }
`

export const buttonStyle = `
	${ h6 }
	${ responsiveStyles('font-size', 14, 13, 13, 13) }
	line-height: 1em;
`

export const handwrittenEyebrow = `
	font-family: ${ secondaryFont };
	font-size: 46px;
	${ responsiveStyles('font-size', 60, 60, 52, 46) }
	font-weight: normal;
	line-height: 0.75em;
	letter-spacing: -0.04em;
	text-transform: lowercase;
`

export const productTagline = `
	${ responsiveStyles('font-size', 34, 32, 30, 26) }
	font-family: ${ secondaryFont };
	letter-spacing: -0.047em;
	line-height: 0.848em;
	text-align: center;
`

export const storyNotes = `
	max-width: 750px;
	p {
		code {
			background: ${ rgba(colors.textColor, 0.1) };
			color: ${ colors.textColor };
			border-radius: 3px;
			padding: .05em .35em .15em;
			font-style: normal;
		}
	}
`
