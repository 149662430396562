import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import Slider from 'react-slick'
import Section from 'src/components/Section'
import Grid from 'src/components/Grid'
import ResponsiveComponent from 'src/components/ResponsiveComponent'
import ScrollEntrance from 'src/components/ScrollEntrance'
import Video from 'src/components/Video'
import { GatsbyImage } from 'gatsby-plugin-image'
import TextLockup from 'src/components/TextLockup'
import themes from 'src/styles/themes'
import { mq, colors, animations, globals, typography } from 'src/styles'
import ArrowLeft from 'src/assets/images/arrow-left.svg'
import ArrowRight from 'src/assets/images/arrow-right.svg'

const imageSizes = {
  large: {
    width: '100%',
    min: '100%',
    max: '100%',
  },
  medium: {
    width: '65%',
    min: '60px',
    max: '200px',
  },
  small: {
    width: '40%',
    min: '60px',
    max: '140px',
  },
}

const Wrapper = styled(Section)`
  text-align: ${ ({ alignment }) => alignment };
  overflow: visible;
`

const ColumnText = styled(TextLockup)`
  ${ ({ textColor }) => textColor === 'pink' ? `color: ${ colors.pink5 };` : '' }
  p {
    max-width: 24em;
  }
  .eyebrow {
    line-height: .55em !important;
    margin-bottom: .2em !important;
    ${ ({ smallEyebrow, eyebrowStyle }) => smallEyebrow && `
      ${ typography.h6 }
      max-width: 10em;
      ${ eyebrowStyle === 'Handwritten' ? `
        font-size: 32px;
        font-family: ${ typography.secondaryFont };
        text-transform: lowercase;
        font-weight: normal;
        letter-spacing: -.01em;
        line-height: .7em;
        padding: .5em 0 1em;
        max-width: 6em;
      ` : `
        font-size: 12px;
      ` }
    ` }
  }
`

const MediaScrollEntrance = styled(ScrollEntrance)`
  display: inline-block;
  vertical-align: top;
  ${ ({ imageSize }) => imageSize ? `
    width: ${ imageSizes[imageSize].width };
    min-width: ${ imageSizes[imageSize].min };
    max-width: ${ imageSizes[imageSize].max };
  ` : `
    width: 100%;
  ` }
`

const ColumnWrapper = styled.div`
  .gatsby-image-wrapper,
  .video-wrapper {
    ${ ({ imageSize }) => imageSize ? `
      margin-bottom: calc(${ imageSizes[imageSize].width } / 8);
    ` : `
      margin-bottom: 24px;
    ` }
  }
  ${ ({ mobileCol }) => mobileCol ? `
    padding-left: 10px;
    padding-right: 10px;
    width: calc((100vw / 14) * 8 + 20px) !important;
    ${ mq.smallAndBelow } {
      width: calc((100vw / 14) * 12 + 20px) !important;
    }
  ` : '' }
  text-align: ${ ({ alignment }) => alignment || 'center' };
  ${ ({ card, theme }) => card ? `
    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      ${ mq.largeAndBelow } {
        justify-content: center;
        min-height: 66vw;
      }
      ${ mq.smallAndBelow } {
        min-height: 100vw;
      }
      background-color: ${ themes[theme].background };
      color: ${ themes[theme].color };
      clip-path: url(#raggedThumb2);
      height: 100%;
      padding: 60px 30px;
      .icon {
        max-width: 300px !important;
      }
      .icon .gatsby-image-wrapper {
        max-width: 300px;
        margin-left: auto;
        margin-right: auto;
        aspect-ratio: 1;
        img {
          object-fit: contain !important;
        }
      }
    }
  ` : '' }
`

const ColumnsGrid = styled.div`
  overflow: visible;
  ${ ({ alignment, vAlign, smallCols, mediumCols, largeCols, colGap, rowGap }) =>
    alignment === 'center' ? `
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 0 !important;
    align-items: ${ vAlign === 'bottom' ? 'baseline' : vAlign };
    margin-left: calc(${ colGap[0] } / -2);
    margin-right: calc(${ colGap[0] } / -2);
    margin-bottom: -${ rowGap[0] };
    width: auto;
    ${ mq.mediumAndUp } {
      margin-left: calc(${ colGap[1] } / -2);
      margin-right: calc(${ colGap[1] } / -2);
      margin-bottom: -${ rowGap[1] };
    }
    ${ mq.largeAndUp } {
      margin-left: calc(${ colGap } / -2);
      margin-right: calc(${ colGap } / -2);
      margin-bottom: -${ rowGap[2] };
    }
    ${ ColumnWrapper } {
      width: ${ 100 / smallCols }%;
      padding-left: calc(${ colGap[0] } / 2);
      padding-right: calc(${ colGap[0] } / 2);
      margin-bottom: ${ rowGap[0] };
      ${ mq.mediumAndUp } {
        width: ${ 100 / mediumCols }%;
        padding-left: calc(${ colGap[1] } / 2);
        padding-right: calc(${ colGap[1] } / 2);
        margin-bottom: ${ rowGap[1] };
      }
      ${ mq.largerAndUp } {
        width: ${ 100 / largeCols }%;
        padding-left: calc(${ colGap[2] } / 2);
        padding-right: calc(${ colGap[2] } / 2);
        margin-bottom: ${ rowGap[2] };
      }
    }
  ` : '' }
`

const StyledSlider = styled(Slider)`
  padding-left: calc(100vw / 14 - 10px);
  overflow: visible !important;
  padding-bottom: calc(100vw / 14 + 30px);
  .slick-list {
    overflow: visible !important;
  }
  .slick-track {
    display: flex;
    align-items: baseline;
  }
`

const IntroText = styled(Grid)`
	position: relative;
	padding-bottom: 10px;
  ${ mq.mediumAndUp } {
    padding-bottom: 20px;
  }
`

const SlideshowDots = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
`

const SlideshowDot = styled.li`
  width: 10px;
  height: 10px;
  color: ${ colors.mainColor };
  background: currentcolor;
  cursor: pointer;
  border-radius: 50%;
  margin: 0 5px;
  border: 2px solid currentcolor;
  transform: scale(0.6);
  transition: transform ${ animations.mediumSpeed } ease-in-out,
    background ${ animations.mediumSpeed } ease-in-out;
  .slick-active & {
    transform: none;
    background: transparent;
  }
`

const ArrowControl = styled.button`
  position: absolute;
  z-index: 1;
  bottom: 0;
  ${ ({ position }) =>
    position === 'left' ? `
    left: calc(100vw / 14);
  ` : `
    left: calc(100vw / 14 + 60px);
  ` }
  
  appearance: none;
	width: 40px;
	height: 40px;
	padding: 0;
	cursor: pointer;
	background: transparent;
  background: ${ colors.pink2 };
  border-radius: 50%;
  padding: 8px;
	color: currentcolor;
	border: none;
	outline: none;
	transition:
    color ${ animations.mediumSpeed } ease-in-out,
    background ${ animations.mediumSpeed } ease-in-out,
    opacity ${ animations.mediumSpeed } ease-in-out;
	&:hover {
    background: ${ colors.pink3 };
	}
	svg {
		width: 100%;
		aspect-ratio: 1;
		display: block;
		* {
			transition: fill ${ animations.mediumSpeed } ease-in-out;
			fill: currentcolor;
      stroke-width: 2px;
      stroke: currentColor;
		}
		&:hover {
			* {
				fill: ${ colors.pink5 };
			}
		}
	}
`

const gridSetup = {
  1: '[1]',
  2: '[1] [1]',
  3: '[1] [1] [1]',
  4: '[1] [1] [1] [1]',
  5: '[1] [1] [1] [1] [1]',
  6: '[1] [1] [1] [1] [1] [1]',
}

const Columns = ({
  className,
  theme,
  prevTheme,
  nextTheme,
  columns,
  alignment,
  verticalAlignment = 'top',
  id,
  desktopColumnCount,
  tabletColumnCount,
  mobileColumnCount,
  imageSize,
  index,
  headline,
  text,
  buttons,
  slideshowOnMobile,
  mobileTheme,
  isMobile,
  cards,
  padded = true
}) => {
  const NextArrow = ({ onClick }) => (
    <ArrowControl
      position="right"
      onClick={onClick}
      aria-label="Go to next image"
      hide={!onClick}
    >
      <ArrowRight />
    </ArrowControl>
  )
  const PrevArrow = ({ onClick }) => (
    <ArrowControl
      position="left"
      onClick={onClick}
      aria-label="Go to previous image"
      hide={!onClick}
    >
      <ArrowLeft />
    </ArrowControl>
  )

  const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    autoplay: false,
    centerMode: false,
    swipe: true,
    accesibility: true,
    speed: 500, // swipe animation speed
    variableWidth: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    appendDots: dots => <SlideshowDots>{dots}</SlideshowDots>,
    customPaging: (slider, i) => (
      <SlideshowDot active={slider === i}>
        {i}
        {slider.current}
      </SlideshowDot>
    ),
  }

  if (!mobileTheme || mobileTheme == null) {
    mobileTheme = theme
  }

  const renderColumns = () => {
    return (
      <Wrapper
        className={className}
        setTheme={isMobile ? mobileTheme : theme}
        prevTheme={prevTheme}
        nextTheme={nextTheme}
        alignment={alignment === null ? 'left' : alignment}
        index={index}
        padded={padded}
      >
        <IntroText small='1 [12] 1'>
          <TextLockup
            alignment="left"
            eyebrow={headline}
            eyebrowUnderline={false}
            text={text}
            textSize={'body'}
            handwrittenEyebrow={true}
            actions={buttons}
          />
        </IntroText>
        <Grid
          small="container"
          // medium="container"
          // larger="2 [10] 2"
          // extraLarge="5 [18] 5"
        >
          <ColumnsGrid
            small={gridSetup[mobileColumnCount] || gridSetup[1]}
            medium={gridSetup[tabletColumnCount] || gridSetup[2]}
            larger={gridSetup[desktopColumnCount] || gridSetup[3]}
            smallCols={mobileColumnCount || 1}
            mediumCols={tabletColumnCount || 2}
            largeCols={desktopColumnCount || 3}
            vAlign={verticalAlignment}
            alignment={alignment}
            rowGap={['7vw', '7vw', '80px']}
            colGap={cards ? ['2vw', '2vw', '2vw'] : ['2vw', '2vw', '4vw']}
            as={alignment === 'center' ? 'div' : Grid}
          >
            {columns.map((column, index) => {
              if (column.media) {
                column.media.sizes =
                  '(min-width: ' +
                  mq.mediumBreakpoint +
                  'px) ' +
                  '200px' +
                  ', (min-width: ' +
                  mq.smallBreakpoint +
                  'px) ' +
                  '200px' +
                  ', ' +
                  '200px'
              }
              return (
                <ColumnWrapper
                  theme={column.theme}
                  alignment={alignment === null ? 'left' : alignment}
                  index={index}
                  colCount={columns.length}
                  imageSize={imageSize || 'large'}
                  key={column.id + '-' + index + '-' + id}
                  card={cards}
                >
                  <div>
                    {column.media && column.media !== null && (
                      <MediaScrollEntrance
                        className='icon'
                        delay={index}
                        imageSize={imageSize || 'large'}
                      >
                        {column.media.file.contentType.includes('video') ? (
                          <Video video={column.media} />
                        ) : (
                          <GatsbyImage
                            image={column.media.gatsbyImageData}
                            alt={column.media.file.fileName}
                            sizes={'800px' || column.media.sizes}
                          />
                        )}
                      </MediaScrollEntrance>
                    )}
                    <ColumnText
                      eyebrowUnderline={!cards}
                      handwrittenEyebrow={true}
                      altUnderline={index % 2}
                      entranceDelay={column.media ? index + 1 : index}
                      eyebrow={column.eyebrow}
                      underlineColor={column.underlineColor}
                      text={column.text}
                      textSize={column.paragraphSize}
                      actions={column.actions}
                      theme={theme}
                      alignment={alignment}
                      textColor={column.textColor}
                      smallEyebrow={columns.length >= 4}
                      eyebrowStyle={column.eyebrowStyle}
                    />
                  </div>
                </ColumnWrapper>
              )
            })}
          </ColumnsGrid>
        </Grid>
      </Wrapper>
    )
  }

  if (!slideshowOnMobile) {
    return renderColumns()
  }

  return (
    <ResponsiveComponent
      small={
        <Wrapper
          setTheme={mobileTheme}
          bottomEdge
          prevTheme={prevTheme}
          nextTheme={nextTheme}
          css={css`
            ${ prevTheme !== mobileTheme ? globals.verticalSpacing('margin-top', 1) : '' }
          `}
        >
          <IntroText small='1 [12] 1'>
            <TextLockup
              alignment="left"
              eyebrow={headline}
              eyebrowUnderline={false}
              text={text}
              textSize={'body'}
              handwrittenEyebrow={true}
              actions={buttons}
            />
          </IntroText>
          <div style={{ overflow: 'hidden' }}>
            <StyledSlider
              {...settings}
            >
              {columns.map((column, index) => {
              if (column.media && column.media.gatsbyImageData) {
                column.media.gatsbyImageData.sizes = '(min-width: ' + mq.mediumBreakpoint + 'px) ' + 86 / desktopColumnCount + 'vw, (min-width: ' + mq.smallBreakpoint + 'px) ' + 86 / tabletColumnCount + 'vw, ' + 86 / mobileColumnCount + 'vw'
                // column.media.gatsbyImageData.sizes = 'TEST 2'
              }
              return (
                <ColumnWrapper
                  theme={column.theme}
                  alignment={alignment === null ? 'left' : alignment}
                  index={index}
                  // imageSize={imageSize || "large"}
                  imageSize={'medium'}
                  colCount={columns.length}
                  key={column.id + '-' + index + '-' + id}
                  mobileCol
                  card={cards}
                >
                  <div>
                    {column.media && column.media !== null && (
                      <MediaScrollEntrance
                        className='icon'
                        delay={index}
                        // imageSize={imageSize || "large"}
                        imageSize={'medium'}
                      >
                        {column.media.file.contentType.includes('video') ? (
                          <Video video={column.media} />
                        ) : (
                          <GatsbyImage
                            image={column.media.gatsbyImageData}
                            alt={column.media.file.fileName}
                            sizes='TEST'
                          />
                        )}
                      </MediaScrollEntrance>
                    )}
                    <ColumnText
                      eyebrowUnderline={!cards}
                      handwrittenEyebrow={true}
                      altUnderline={index % 2}
                      entranceDelay={column.media ? index + 1 : index}
                      eyebrow={column.eyebrow}
                      underlineColor={column.underlineColor}
                      text={column.text}
                      textSize={column.paragraphSize}
                      actions={column.actions}
                      theme={theme}
                      alignment={alignment}
                      textColor={column.textColor}
                      smallEyebrow={columns.length >= 4}
                      eyebrowStyle={column.eyebrowStyle}
                    />
                  </div>
                </ColumnWrapper>
              )
            })}
            </StyledSlider>
          </div>
        </Wrapper>
      }
      large={renderColumns()}
    />
  )
}

export default Columns
