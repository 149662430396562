import React, { useRef } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import Slider from 'react-slick'
import ArrowLeft from 'src/assets/images/arrow-left.svg'
import ArrowRight from 'src/assets/images/arrow-right.svg'

import Section from 'src/components/Section'
import ResponsiveComponent from 'src/components/ResponsiveComponent'
import TextLockup from 'src/components/TextLockup'
import Grid from 'src/components/Grid'
import TextLink from 'src/components/TextLink'
import CocktailCard from 'src/components/CocktailCard'
import { colors, mq, animations } from 'src/styles'

const Wrapper = styled(Section)`

`

const ColumnWrapper = styled.div`
	h1, h2, h3 {
		max-width: 20em;
	}
	h4, h4 {
		max-width: 26em;
	}
	h6 {
		max-width: 16em;
	}
	p {
		max-width: 40em;
	}
`

const InfoWrap = styled(ColumnWrapper)`
	position: relative;
	padding-bottom: 30px;
	${ mq.mediumAndUp } {
		padding-bottom: 50px;
	}
	p {
		max-width: 50em;
		text-wrap: balance;
	}
	${ mq.mediumAndBelow } {
		.floater-button {
			display: none;
		}
	}
	${ mq.mediumAndUp } {
		display: flex;
		justify-content: space-between;
	}
	${ mq.largeAndUp } {
		.lockup-actions {
			display: none;
		}
	}
`

const SliderControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
	padding-top: 20px;
  pointer-events: none;
  button {
    pointer-events: all;
  }
	${ mq.mediumAndBelow } {
		padding-top: 14px;
	}
`

const CocktailsWrap = styled.div`
	position: relative;
	overflow: hidden;
	padding: 0 calc(100vw / 14);
	${ mq.largerAndUp } {
		padding: 0 calc(100vw / 14);
	}
`

const ArrowControl = styled.button`
	appearance: none;
	width: 40px;
	height: 40px;
	padding: 0;
	cursor: pointer;
	background: transparent;
  background: ${ colors.pink2 };
  border-radius: 50%;
  padding: 8px;
	color: currentcolor;
	border: none;
	outline: none;
	transition:
    color ${ animations.mediumSpeed } ease-in-out,
    background ${ animations.mediumSpeed } ease-in-out,
    opacity ${ animations.mediumSpeed } ease-in-out;
	&:hover {
    background: ${ colors.pink3 };
	}
	svg {
		width: 100%;
		aspect-ratio: 1;
		display: block;
		* {
			transition: fill ${ animations.mediumSpeed } ease-in-out;
			fill: currentcolor;
      stroke-width: 2px;
      stroke: currentColor;
		}
		&:hover {
			* {
				fill: ${ colors.pink5 };
			}
		}
	}
`

const StyledSlider = styled(Slider)`
	overflow: visible !important;
	.slick-list,
	.slick-track {
		overflow: visible !important;
	}
	.slick-track {
		${ mq.largerAndUp } {
			display: flex;
			justify-content: flex-end;
		}
	}
	&:hover {
		${ ArrowControl } {
			transform: none;
		}
	}
`

const Slide = styled.div`
	padding: 0 14px 0 0;
	${ mq.largerAndUp } {
		padding: 0 20px 0 0;
	}
	> div {
		width: auto;
	}
`

const FeaturedCocktails = ({
	className,
	theme,
	nextTheme,
	prevTheme,
	info,
	cocktails,
	index
}) => {
	const customSlider = useRef()

	const NextArrow = ({ onClick }) => <ArrowControl position="right" onClick={onClick} aria-label='Go to next image'><ArrowRight/></ArrowControl>
	const PrevArrow = ({ onClick }) => <ArrowControl position="left" onClick={onClick} aria-label='Go to previous image'><ArrowLeft/></ArrowControl>

	function goToNextSlide () {
		customSlider.current.slickNext()
	}

	function goToPrevSlide () {
		customSlider.current.slickPrev()
	}

	const settings = {
		dots: false,
		arrows: false,
		infinite: false,
		autoplay: false,
		centerMode: false,
		swipe: true,
		accesibility: false,
		speed: 500, // swipe animation speed
		variableWidth: false,
		slidesToShow: 5,
		slidesToScroll: 1,
		// nextArrow: <NextArrow/>,
		// prevArrow: <PrevArrow onClick={goToPrevSlide}/>,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 4,
				}
			},
			{
				breakpoint: 1000,
				settings: {
					slidesToShow: 3,
				}
			},
			{
				breakpoint: 769,
				settings: {
					slidesToShow: 1.7
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1.5
				}
			},
			{
				breakpoint: 500,
				settings: {
					slidesToShow: 1.1
				}
			}
		]
	}

	return (
		<Wrapper className={className} setTheme={theme} nextTheme={nextTheme} prevTheme={prevTheme} index={index}>
			{info && (
				<Grid
					small='1 [12] 1'
					vAlign='top'
				>
					<InfoWrap>
						<TextLockup
							eyebrowUnderline={false}
							eyebrow={info.eyebrow}
							text={info.text}
							textSize={'body'}
							handwrittenEyebrow={true}
							actions={info.actions}
							alignment={'left'}
						/>
						<div className='floater-button'>
							{info.actions.map((action, index) => {
								return (
									<div key={'cocktail-button-' + index}>
										<TextLink
											to={action.to || action.linkToPage.slug}
											external={action.to}
											target={action.openInNewTab ? '_blank' : ''}
											title={action.label}
											name={action.label}
										>
											{action.label}
										</TextLink>
									</div>
								)
							})}
						</div>
					</InfoWrap>
				</Grid>
			)}
			<div>
				<CocktailsWrap>
					<div>
						<StyledSlider
							{...settings}
							ref={slider => (customSlider.current = slider)}
						>
							{cocktails.map((slide, index) => {
								if (!slide.image) {
									return false
								}
								return (
									<Slide key={slide.id + '_slide'} slideCount={cocktails.length}>
										<CocktailCard
											title={slide.title}
											tagline={slide.tagline}
											image={slide.thumbnail}
											slug={slide.slug}
											index={index}
											sizes={'(min-width: ' + mq.largerBreakpoint + 'px) ' + '467px, ' + '(min-width: ' + mq.mediumBreakpoint + 'px) ' + '28vw, ' + '(min-width: ' + mq.smallBreakpoint + 'px) ' + '42vw, 86vw'}
										/>
									</Slide>
								)
							})}
						</StyledSlider>
					</div>
					<SliderControls css={css`display: flex; align-items: center; justify-content: flex-start;`}>
						<PrevArrow onClick={goToPrevSlide}/>
						<NextArrow onClick={goToNextSlide}/>
					</SliderControls>
				</CocktailsWrap>
			</div>
		</Wrapper>
	)
}

export default FeaturedCocktails
