import React, { useRef } from 'react'
import withSizes from 'react-sizes'
import styled from '@emotion/styled'
import Section from 'src/components/Section'
import ThemeSelector from 'src/components/ThemeSelector'
import Slider from 'react-slick'
import ArrowLeft from 'src/assets/images/arrow-left.svg'
import ArrowRight from 'src/assets/images/arrow-right.svg'
import { colors, mq, animations, typography, util, globals } from 'src/styles'
import ContentfulRichText from 'src/components/ContentfulRichText'
import RoughEdge from 'src/components/RoughEdge'
import ReviewStar from 'src/assets/images/ette-star.svg'
import { themes } from 'src/styles/themes'

const Wrapper = styled(Section)`
  z-index: 2;
  ${ mq.largeAndBelow } {
    ${ ({ padBottom, setTheme, nextTheme }) => padBottom !== false && `
			${ setTheme === nextTheme ? `
				${ globals.verticalSpacing('margin-bottom', 0.5) }
			` : '' }
		` }
    ${ globals.verticalSpacing('padding-top') }
    ${ globals.verticalSpacing('padding-bottom') }
    
    .section-rough-edge {
      display: none;
    }
    ${ ({ cardTheme }) => (cardTheme === 'default' || cardTheme === 'midPink') ? `
      background: ${ colors.pink2 };
      --bg-color: ${ colors.pink2 };
      --text-color: ${ colors.bgColor };
    ` : `
      background: ${ themes[cardTheme].background };
      --bg-color: ${ themes[cardTheme].background };
      --text-color: ${ themes[cardTheme].color };
    ` }
  }
`

const StyledRoughEdge = styled(RoughEdge)`
  * {
    fill: ${ colors.pink2 };
    ${ mq.largeAndBelow } {
      fill: var(--bg-color);
    }
  }
  ${ mq.largerAndUp } {
    display: none;
  }
`

const ArrowControl = styled.button`
  appearance: none;
  width: 50px;
  height: 50px;
  padding: 0;
  cursor: pointer;
  position: absolute;
  z-index: 1;
  top: calc(50% - 25px);
  ${ ({ position }) =>
    position === 'left' ? `
    left: ${ 100 / 26 }vw;
  ` : `
    right: ${ 100 / 26 }vw;
  ` }
  background: transparent;
  // color: ${ colors.pink4 };
  color: var(--main-color);
  border: none;
  outline: none;
  transition: color ${ animations.mediumSpeed } ease-in-out,
              opacity ${ animations.mediumSpeed } ease-in-out,
              transform ${ animations.mediumSpeed } ease-in-out;
  ${ ({ hide }) =>
    hide &&
    `
    // opacity: 0;
    // pointer-events: none;
  ` }
  &:hover {
    ${ ({ position }) => position === 'right' ? `
      transform: translate3d(10%, 0, 0);
    ` : `
      transform: translate3d(-10%, 0, 0);
    ` }
  }
  svg {
    width: 50px;
    height: 50px;
    display: block;
    * {
      stroke: currentcolor;
      stroke-width: 0px;
      stroke-linecap: butt;
      stroke-linebutt: butt;
      stroke-linejoin: miter;
      fill: currentcolor;
    }
  }
`

const Slide = styled(ThemeSelector)`
  padding: 4vw 5vw 3vw;
  text-align: center;
  position: relative;
  height: 100%;
  background: transparent;
  .slide-svg {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    * {
      ${ ({ setTheme }) => (setTheme === 'default' || setTheme === 'midPink') ? `
        fill: ${ colors.pink2 };
      ` : `
        fill: var(--bg-color);
      ` }
    }
    ${ mq.largeAndBelow } {
      display: none;
    }
  }
`
const SlideWrap = styled.div`
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  z-index: 2;
  ${ mq.largerAndUp } {
    opacity: 0;
    transform: translate3d(0, 10%, 0);
    transition: transform ${ animations.mediumSpeed } ease-in-out,
      opacity ${ animations.mediumSpeed } ease-in-out;
    transition-delay: 0;
    .slick-current & {
      transition-delay: 0.4s;
      opacity: 1;
      transform: none;
    }
  }
  h6 {
    flex-grow: 0;
    ${ ({ cardTheme }) => (cardTheme === 'default' || cardTheme === 'midPink') ? `
      color: ${ colors.pink5 };
    ` : `
      color: var(--main-color);
    ` }
    margin-bottom: 8px;
    margin-top: 8px;
    flex-grow: 0;
  }
`

const QuoteWrap = styled(ContentfulRichText)`
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 0;
  p {
    ${ typography.reviewQuote }
    max-width: 14em;
    min-height: 0;
    margin: 0 auto;
  }
`

const StyledSlider = styled(Slider)`
  ${ ({ cardTheme }) => cardTheme ? `
    --bg-color: ${ themes[cardTheme].background };
    --main-color: ${ themes[cardTheme].hoverColor };
  ` : '' }
  ${ mq.largeAndBelow } {
    padding-bottom: 38px;
  }
  .slick-track {
    display: flex;
    align-items: stretch;
    .slick-slide {
      width: 50vw;
      height: auto;
      padding: 0 8px;
      ${ mq.largerAndBelow } {
        width: 60vw;
      }
      ${ mq.largeAndBelow } {
        width: 100%;
      }
      > div {
        height: 100%;
      }
    }
  }
`

const Clips = ['raggedThumb1', 'raggedThumb2', 'raggedThumb3']

const RatingsWrap = styled.div`
  width: 100%;
  flex-grow: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  svg * {
    ${ ({ cardTheme }) => (cardTheme === 'default' || cardTheme === 'midPink') ? `
      fill: ${ colors.yellow };
    ` : `
      fill: var(--main-color);
    ` }
  }
`

const StyledStar = styled(ReviewStar)`
  display: block;
  position: static;
  ${ util.responsiveStyles('margin-left', 6, 5, 4, 3) }
  ${ util.responsiveStyles('margin-right', 6, 5, 4, 3) }
  overflow: visible;
  * {
    fill: ${ colors.yellow };
    transform-origin: 50% 50%;
  }
  animation: ${ animations.upAndDown } 2s ease-in-out infinite;
  animation-delay: ${ ({ delay }) => delay * -200 + 'ms' };
  ${ util.responsiveStyles('width', 28, 24, 20, 18) }
`

const SlideshowDots = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  z-index: 9999;
`

const SlideshowDot = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 40px;
  &:after {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    color: var(--text-color);
    background: currentcolor;
    cursor: pointer;
    border-radius: 50%;
    margin: 0 5px;
    transform: scale(0.6);
    transition: transform ${ animations.mediumSpeed } ease-in-out,
      background ${ animations.mediumSpeed } ease-in-out;
  }
  .slick-active &:after {
    transform: none;
    background: var(--text-color);
  }
`

const ReviewSlideshow = ({
  className,
  reviews,
  nextTheme,
  prevTheme,
  isFirstSection,
  winWidth,
  cardTheme,
  index,
}) => {
  if (!reviews || reviews.length === 0) {
    return false
  }
  const customSlider = useRef()

  const NextArrow = ({ onClick }) => (
    <ArrowControl
      position="right"
      onClick={onClick}
      aria-label="Go to next image"
      hide={!onClick}
    >
      <ArrowRight />
    </ArrowControl>
  )
  const PrevArrow = ({ onClick }) => (
    <ArrowControl
      position="left"
      onClick={onClick}
      aria-label="Go to previous image"
      hide={!onClick}
    >
      <ArrowLeft />
    </ArrowControl>
  )

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    autoplay: false,
    centerMode: true,
    swipe: true,
    variableWidth: true,
    accesibility: true,
    speed: 500, // swipe animation speed
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          arrows: false,
          variableWidth: false,
          centerMode: false,
          dots: true,
          appendDots: dots => <SlideshowDots>{dots}</SlideshowDots>,
          customPaging: (slider, i) => (
            <SlideshowDot active={slider === i}>
              {i}
              {slider.current}
            </SlideshowDot>
          ),
        },
      },
    ],
  }

  function renderReviewStars (num) {
    return [...Array(num)].map((e, i) => <StyledStar key={i} delay={i} />)
  }
  return (
    <Wrapper
      className={className}
      setTheme="default"
      nextTheme={nextTheme}
      prevTheme={prevTheme}
      paddingTop={false}
      index={index}
      cardTheme={cardTheme}
    >
      <StyledRoughEdge position="top" />
      <div style={{ overflow: 'hidden' }}>
        <StyledSlider
          {...settings}
          ref={slider => (customSlider.current = slider)}
          cardTheme={cardTheme}
        >
          {reviews.map((slide, index) => {
            const svgStars = renderReviewStars(slide.rating)
            return (
              <Slide
                key={slide.id + '_slide'}
                slideCount={reviews.length}
                clipPathId={Clips[3 % (index + 1)]}
                setTheme={cardTheme}
              >
                <SlideWrap cardTheme={cardTheme}>
                  <RatingsWrap cardTheme={cardTheme}>{svgStars}</RatingsWrap>
                  <QuoteWrap richText={slide.quote} />
                  <h6>{slide.author}</h6>
                </SlideWrap>
                <svg
                  className="slide-svg"
                  viewBox="0 0 995 447"
                  preserveAspectRatio="none"
                >
                  <g
                    id="Symbols"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                  >
                    <path
                      d="M18.590768,439.385946 C-4.86722194,418.402341 11.0326441,377.968932 8.50484345,349.012715 C5.97704281,306.01712 21.1286798,268.286292 11.0174773,225.290697 C-1.5810811,164.949451 -6.56084837,100.340233 13.5452779,40.1464002 C23.6564805,8.55779985 43.8687744,4.17049424 132.341797,4.17049424 C294.121038,4.17049424 450.839622,-1.97699838 612.618863,0.655384981 C713.730888,2.41030723 817.370715,0.655384981 918.48274,2.41030723 C1006.95576,4.16522947 984.200501,36.6365558 986.728302,60.3280061 C991.783903,148.074118 999.377416,236.697692 991.794014,323.566343 C989.266214,351.645099 994.321815,378.846393 991.794014,406.925149 C991.794014,414.822299 996.849616,430.6166 984.210613,436.758827 C969.043809,445.533439 931.126799,444.655978 905.848793,445.533439 C741.541751,449.920744 579.76251,442.895791 415.455468,444.650713 C326.982446,445.528174 238.509424,445.528174 150.036401,445.528174 C122.230594,445.528174 33.7575718,449.91548 18.590768,439.385946"
                      id="Fill-1-Copy"
                      fill="#EDD8D0"
                    ></path>
                  </g>
                </svg>
              </Slide>
            )
          })}
        </StyledSlider>
      </div>
      <StyledRoughEdge position="bottom" delay={1} />
    </Wrapper>
  )
}

const sizesToProps = ({ width, height }) => ({
  winWidth: width,
})

export default withSizes(sizesToProps)(ReviewSlideshow)
