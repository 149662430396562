import React, { useContext } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { OverlayContext } from 'src/state/OverlayState'
import { useStaticQuery, graphql } from 'gatsby'
import Section from 'src/components/Section'
import ScrollEntrance from 'src/components/ScrollEntrance'
import ThemeSelector from 'src/components/ThemeSelector'
import TextLockup, { underlineColors } from 'src/components/TextLockup'
import Grid from 'src/components/Grid'
import Button from 'src/components/Button'
import EyebrowUnderline from 'src/assets/images/ette-eyebrow-underline.svg'
import { themes } from 'src/styles/themes'
import ProductSlider from 'src/components/MerchATF/components/ProductSlider'
import { decodeProductId } from 'src/utils/format'
import BeverageProductSwitcher from 'src/components/BeverageProductSwitcher'

import { colors, mq, typography, globals, util } from 'src/styles'

const Wrapper = styled(Section)`
  ${ ({ isFirstSection }) => isFirstSection ? `
    padding-top: 0 !important;
  ` : '' }
  ${ ({ productPage }) => productPage ? `
    padding-bottom: 0 !important;
  ` : '' }
`

const ImageWrap = styled.div`
  padding-bottom: 100%;
  clip-path: url(#raggedThumbFullBleed);
  position: relative;
  .slick-slide,
  .slick-list,
  .slick-track {
    height: 100% !important;
  }
  > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  ${ mq.largerAndUp } {
    min-height: 100%;
    padding-bottom: 100%;
    ${ ({ imageOnLeft }) => imageOnLeft ? `
      clip-path: url(#raggedThumbBleedLeft);
    ` : `
      clip-path: url(#raggedThumbBleedRight);
    ` }
  }
`

const InfoWrap = styled(Grid)`
  height: 100%;
  align-items: center;
  ${ mq.largerAndUp } {
    ${ ({ isFirstSection }) =>
      isFirstSection ? `
      ${ globals.verticalSpacing('padding-top') }
    ` : '' }
  }
  h4 {
    width: 100%;
    text-align: center;
  }
  p {
    max-width: 36em;
    margin-top: 0;
    margin-bottom: 0;
  }
  svg {
    display: block;
    &.eyebrow-underline {
      margin: 20px auto;
      * {
        fill: ${ ({ theme }) => theme ? underlineColors[theme] : colors.mainColor };
      }
    }
  }
`

const InfoCard = styled(ThemeSelector)`
  clip-path: url(#cardSquare);
  ${ globals.verticalSpacing('padding-top') }
  ${ globals.verticalSpacing('padding-bottom') }
  ${ globals.verticalSpacing('padding-left', 0.5) }
  ${ globals.verticalSpacing('padding-right', 0.5) }
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  ${ mq.mediumAndUp } {
    ${ globals.verticalSpacing('padding-top', 0.6) }
    ${ globals.verticalSpacing('padding-bottom', 0.6) }
  }
  ${ ({ setTheme }) => setTheme === 'default' ? `
    background: ${ colors.white };
  ` : '' }
`

const ProductInfo = styled.div`
  ${ util.responsiveStyles('margin-top', 40, 30, 24, 22) }
  ${ util.responsiveStyles('margin-bottom', 40, 30, 24, 22) }
  text-align: center;
  h6 {
    ${ util.responsiveStyles('margin-bottom', 40, 30, 24, 22) }
  }
`

const Tagline = styled.div`
  > div {
    margin: 0 auto;
    ${ typography.productTagline }
    max-width: 10em;
    u {
      display: inline-block;
      position: relative;
      text-decoration: none;
      svg {
        position: absolute;
        width: 100%;
        display: block;
        top: calc(100% - 0.1em);
        overflow: visible;
        height: 0.2em;
        * {
          fill: none;
          // stroke-width: 1.5px;
          stroke: var(--main-color);
        }
      }
    }
  }
`

const TaglineStar = styled.span`
  ${ util.responsiveStyles('font-size', 54, 36, 32, 24) }
  font-family: ${ typography.secondaryFont };
  text-align: center;
  color: var(--main-color);
  display: block;
  margin-bottom: 0.25em;
`

const ProductDetailWrap = styled.div`
  ${ util.responsiveStyles('margin-top', 26, 22, 22, 20) }
  display: flex;
  justify-content: center;
  h6 {
    margin-top: 0;
    display: inline-block;
  }
  span {
    color: ${ colors.pink4 };
  }
`

const FeaturedProduct = ({
  className,
  theme,
  cardTheme,
  prevTheme,
  nextTheme,
  isFirstSection,
  beverageProduct,
  productPage,
  imageOnLeft = true,
  index,
  purchaseLink,
}) => {
  const { allShopifyProductVariant, allContentfulBeverageProduct } = useStaticQuery(
    graphql`
      query {
        allContentfulBeverageProduct {
          edges {
            node {
              ...BeverageProduct
            }
          }
        }
        allShopifyProductVariant {
          edges {
            node {
              ...Variant
            }
          }
        }
      }
    `
  )

  const {
    toggleModal,
    setCurrentVariant,
    setCurrentProduct
  } = useContext(OverlayContext)

  const beverageProductId = decodeProductId(beverageProduct.shopifyProductId)

  const variant = allShopifyProductVariant.edges.filter(variant => variant.node.product.shopifyId === beverageProductId)[0]?.node
  const product = variant?.product

  const getMetafield = key => {
    const metafields = product?.metafields?.filter(field => field.key === key)
    if (metafields && metafields.length > 0) {
      return metafields[0].value
    }
    return false
  }
  const abv = getMetafield('abv')
  const volume = getMetafield('volume')
  let tagline = getMetafield('tagline')

  if (tagline) {
    tagline = tagline.replace(/<u>/g, "<u><svg width='115px' height='5px' viewBox='0 0 115 5' preserveAspectRatio='none'><path d='M0.0810546875,4.28027344 C25.2267225,-2.34828249 39.183906,1.56675172 67.3291088,2.38867188 C82.9115465,2.84372347 98.500327,2.02213543 114.085938,1.83886719' id='Path-2-Copy' stroke='var(--main-color)'></path></svg>")
  }

  const handleAddToCart = () => {
    toggleModal('modalLocationSelector')
    setCurrentVariant(variant.shopifyId)
    setCurrentProduct(product)
  }

  return (
    <Wrapper
      className={className}
      setTheme='default'
      prevTheme={prevTheme}
      nextTheme={nextTheme}
      isFirstSection={isFirstSection}
      index={index}
      productPage={productPage}
    >
      <Grid
        small="[12]"
        large='[12] 1 [10] 1'
        larger='[12] 2 [8] 2'
        vAlign="stretch"
        rowGap="6vw"
        gridDirection={imageOnLeft ? 'ltr' : 'rtl'}
      >
        <div>
          <ImageWrap isFirstSection={isFirstSection} imageOnLeft={imageOnLeft}>
            <ProductSlider
              productImages={product.images}
              fullHeight={true}
            />
          </ImageWrap>
        </div>

        <div>
          <InfoWrap
            small="container"
            // medium="2 [10] 2"
            large="[1]"
            isFirstSection={isFirstSection}
            theme={cardTheme}
          >
            <InfoCard setTheme={cardTheme} as={ScrollEntrance}>
              <h4 css={css`${ typography.eyebrow } margin: 0; font-size: 18px;`}>{product.title}</h4>
              <EyebrowUnderline className="eyebrow-underline" />
              <TextLockup
                text={<span dangerouslySetInnerHTML={{ __html: product?.descriptionHtml }} />}
                textSize="bodySmall"
                alignment="center"
                setTheme={cardTheme}
              />

              <BeverageProductSwitcher
                beverages={allContentfulBeverageProduct.edges}
                pathname={'/shop/' + product.handle}
              />

              {(abv || volume) && (
                <ProductDetailWrap accentColor={underlineColors[theme]}>
                  <h6>
                    {abv && (<>{abv} ABV</>)}
                    {abv && volume && (
                      <>&nbsp;<span>&#8226;</span>&nbsp;</>
                    )}
                    {volume && (<>{volume} ML</>)}
                  </h6>
                </ProductDetailWrap>
              )}

              <ProductInfo>
                {/* <h6> 1 &nbsp;&nbsp;&nbsp; 2 &nbsp;&nbsp;&nbsp; 6 </h6> */}
                <Button
                  setTheme={themes[cardTheme].buttonTheme}
                  title={product.buttonText || 'Buy Now'}
                  name={product.buttonText || 'Buy Now'}
                  onClick={handleAddToCart}
                  disabled={!variant.availableForSale}
                >
                  {variant.availableForSale ? (
                    product.buttonText || 'Buy Now'
                  ) : 'Sold Out'}
                </Button>
              </ProductInfo>

              {tagline && (
                <Tagline>
                  <TaglineStar>*</TaglineStar>
                  <div dangerouslySetInnerHTML={{ __html: tagline }} />
                </Tagline>
              )}
            </InfoCard>
          </InfoWrap>
        </div>
      </Grid>
    </Wrapper>
  )
}

export default FeaturedProduct
